.uploadPopup {
	height: 500px;
	width: 800px;
	background-color: white;
	border: 1px solid rgb(230, 230, 230);
	box-shadow: 01px 4px 8px 0px rgba(0, 0, 0, 0.2);

	.closeWrpr {
		height: 30px;
		width: 30px;
		z-index: 99999;
		position: absolute;
		right: 0px;
		top: 0px;
		// background-color: wheat;

		&:after {
			content: "";
			background-image: url("../../../../../assets/imgs/close-x.png");
			display: block;
			height: 7px;
			width: 7px;
		}
	}

	.inputWrpr {
		position: absolute;
		z-index: 1;

		.hasSelection {
			background-color: red;
		}
	}

	.sliderThumb {
		height: 20px;
		width: 20px;
		background-color: red;
		border-radius: 50%;
		border: 10px solid green;
		box-sizing: content-box;
	}
}

.handle {
	background-color: pink;
}

.range {
	background-color: rgba(100, 100, 100, 0.3);
}

.sliderWrapper {
	height: 30px;
	width: 100%;
	bottom: 0;
	position: absolute;
	z-index: 99999;
	background: rgba(221, 221, 221, 0.4);

	.innerSliderWrapper {
		width: 100%;
		padding: 0px 20px;
	}
}

.cropWrapper {
	height: 430px;
}

.selectBtn {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	font-size: 18px;
	border: 1px solid #1059a6;
	color: #1059a6;
	padding: 20px 52px;
	border-radius: 35px;
	cursor: pointer;
}

.ftr {
	height: 70px;
}

.changeBtn {
	white-space: nowrap;
	border: 1px solid #1059a6;
	color: #1059a6;
	margin-right: 14px;
	height: 30px;
	width: 180px;
	border-radius: 2px;
	cursor: pointer;
}

.setBtn {
	white-space: nowrap;
	border: 1px solid #1059a6;
	background-color: #1059a6;
	color: white;
	margin-right: 14px;
	height: 30px;
	width: 180px;
	border-radius: 2px;
	cursor: pointer;

	&.disabled {
		filter: opacity(0.5);
	}
}
