@import "../../colors.scss";

.header {
	height: 66px;
	position: sticky;
	top: 0px;
	z-index: 1000;
	background-color: transparent;
	transition: background-color 0.5s;

	&.dark {
		border-bottom: 1px solid #979797;
		background-color: white;

		.navs-wrpr {
			a {
				color: $darkText;
			}
		}
	}

	.cntnt-wrpr {
		// padding: 0px 16px;
	}

	.navs-wrpr {
		a {
			text-decoration: none;
			color: white;
			padding: 0px 19px;

			&:hover {
				color: $lightBlue;
			}

			&.selected {
				color: $lightBlue;
			}
		}
		.btn {
			background-color: $yellow;
			color: $darkText;
			padding: 6px 23px;
			border-radius: 2px;
		}
	}
}

//

@media screen and (min-width: 651px) and (max-width: 1040px) {
	.header {
		height: 96px;
	}
	.navs-wrpr {
		flex-flow: column;
		align-items: flex-end;

		.btn {
			margin-top: 10px;
			margin-left: auto;
		}
	}
}

@media screen and (max-width: 650px) {
	.header,
	.header.dark {
		.mobile-phone {
			color: white;
		}
		.navs-wrpr {
			transform: scaleY(0) translateY(-100%);
			padding-top: 42px;
			flex-flow: column;
			position: fixed;
			width: 100%;
			justify-content: center;
			left: 0px;
			top: 64px;
			background-color: $darkColor;
			padding-bottom: 62px;
			transition: transform 0.2s;

			.all-navs {
				flex-flow: column;
				width: 100%;
			}

			&.show {
				transform: scaleY(1) translateY(0%);
			}

			a {
				height: 66px;
				color: white;
				font-size: 22px;

				&.selected {
					color: $lightBlue;
				}

				&.btn {
					height: 38px;
					line-height: 24px;
					margin-top: 30px;
					color: $darkText;
				}
			}
		}
	}

	.header {
		&.dark {
			.mobile-phone {
				color: $darkText;
			}
		}
	}

	.banner-section {
		height: 636px;
	}

	// .client-testimonials
	// .our-expertise
	// {
	// 	display: none;
	// }
}
