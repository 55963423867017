.AddPropertyPage {
	background-color: white;
	position: fixed;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1002;
	width: 414px;
	// height: 850px;
	padding: 40px 30px;
	overflow: auto;
	max-height: calc(100% - 40px);
}

.backdrop {
	background-color: rgba(229, 229, 229, 0.9);
	position: fixed;
	z-index: 1001;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	pointer-events: none;
}

.thumbsWrapper {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 66px 66px 66px 66px;
	gap: 10px 24px;
	justify-content: center;
	margin-bottom: 18px;
}

.inputWrpr {
	margin-bottom: 8px;

	.fieldWrpr {
		margin-left: 14px;
		flex: 1;

		&:nth-child(1) {
			margin-left: 0px;
		}
	}

	label {
		color: #222f33;
		margin-bottom: 8px;
		display: block;
	}
	input,
	select,
	textarea {
		height: 34px;
		display: block;
		width: 100%;
		border: 1px solid #2e4c79;
		padding: 6px 12px;
		resize: none;
		font-family: "slate reg";

		&::placeholder {
			font-family: "slate reg";
		}
	}

	textarea {
		height: 74px;
	}

	.errorMsg {
		color: rgb(172, 5, 5);
		font-size: 11px;
		height: 11px;
		margin-top: 4px;
	}

	.pdfUploadLbl {
		height: 34px;
		display: flex;
		width: 100%;
		border: 1px solid #2e4c79;
		padding: 6px 12px;
		resize: none;
		font-family: "slate reg";
		cursor: pointer;
	}
}

.closeBtn {
	position: absolute;
	top: 14px;
	right: 12px;
	// background-color: rebeccapurple;
	height: 28px;
	width: 28px;
	cursor: pointer;

	&:after {
		content: "";
		background-image: url("../../../assets/imgs/close-x.png");
		display: block;
		height: 7px;
		width: 7px;
	}
}

.submit {
	display: block;
	width: 100%;
	height: 30px;
	border: none;
	background-color: #297ba9;
	color: white;
	border-radius: 2px;
	cursor: pointer;
	margin-top: 30px;
}
