.mapViewPage {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9999;
	left: 0px;
	top: 0px;

	background-color: rgba(229, 229, 229, 0.9);

	.mapView {
		position: fixed;
		height: 742px;
		width: 1034px;
		z-index: 9999;
		max-height: 90%;
		max-width: 90%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 58px 42px;
		background-color: white;

		.closeBtn {
			position: absolute;
			top: 14px;
			right: 12px;
			// background-color: rebeccapurple;
			height: 28px;
			width: 28px;
			cursor: pointer;

			&:after {
				content: "";
				background-image: url("../../../../assets/imgs/close-x.png");
				display: block;
				height: 7px;
				width: 7px;
			}
		}
	}
}
.mapMarker {
	// background-image: url();
}
