.thumbWrapper {
  height: 50px;
  width: 66px;
  border: 1px solid #2e4c79;
  border-radius: 2px;
  overflow: hidden;

  &.hasImg {
    border-color: #2e4c795e;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.459);
    top: 0px;
    left: 0px;
    z-index: 999;

    &::after {
      content: "";
      display: block;
      height: 30px;
      width: 30px;
      border-radius: 50px;
      border: 4px solid green;
      border-color: transparent green green green;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      animation: rotate 1s 0s infinite forwards ease-in-out;
    }
  }

  .done {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.459);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    &::after {
      content: "";
      display: block;
      height: 20px;
      width: 10px;
      background-color: transparent;
      border: 2px solid green;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(36deg);
      border-width: 0px 3px 3px 0px;
    }
  }

  .delete {
    position: absolute;
    z-index: 9999;
    color: rgb(163, 17, 17);
    background-color: #ffffff;
    border-radius: 2px;
    height: 14px;
    width: 14px;
    padding: 10px;
    right: 0px;
    top: 0px;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
