.adminPage {
	background-color: #2e3a60;
	min-height: 600px;
	display: flex;
	flex-direction: column;

	.listWrapper {
		width: 100%;
		max-width: 1190px;
		margin: 40px auto;
		background-color: white;
		height: 100%;
		flex: 1;

		.propLine {
			height: 40px;
			border-bottom: 1px solid #e2e2e2;
			margin: 0px 18px;
			padding: 0px 12px;

			.name {
				width: 30%;
				color: #222f33;
			}

			a {
				color: #297ba9;
			}

			.btn {
				padding: 0px 6px;
				cursor: pointer;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	background-color: rgba(229, 229, 229, 0.9);
	z-index: 1009;

	.popup {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 294px;
		width: 100%;
		max-width: 414px;
		background-color: white;
		transform: translate(-50%, -50%);
		border-radius: 2px;

		h3 {
			color: #222f33;
			max-width: 264px;
			text-align: center;
			margin-top: 68px;
			line-height: 22px;

			&.error {
				color: rgb(187, 13, 13);
			}
		}

		.input {
			height: 50px;
			border: 1px solid #2e4c79;
			display: block;
			padding: 0px 20px;
			width: 280px;
			margin-top: 28px;

			&.error {
				border-color: rgb(187, 13, 13);
			}
		}

		.submit {
			background-color: #2e4c79;
			height: 66px;
			border-radius: 0px 0px 2px 2px;
			margin-top: 38px;
			width: 100%;
			color: white;
			cursor: pointer;
		}
	}
}
