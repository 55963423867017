.detailsPage {
  max-width: 1115px;
  margin: 0px auto;
  margin-top: 62px;

  .leftSec {
    h2 {
      color: #111b47;
      padding-top: 2px;
      margin-bottom: 10px;
    }

    .imgsWrpr {
      width: 729px;
      margin-top: 26px;

      .mainImgWrpr {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        .tag {
          position: absolute;
          left: -12px;
          top: 42px;
          background-color: #f2c94c;
          z-index: 111;
          height: 44px;
          padding: 0px 44px;
          text-transform: capitalize;
          border-radius: 2.5px;
        }

        .mainImg {
          // margin-bottom: 16px;
        }
      }

      .thumb {
        margin-right: 28px;
        height: 66px;
        width: 66px;

        &.selected {
          border: 3px solid #f2c94c;
        }

        &:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
  }

  .rightSec {
    width: 350px;
    border: 1px solid #d8d8d8;
    margin-left: 30px;
    border-radius: 2px;

    margin-bottom: auto;
    position: sticky;
    top: 80px;

    .descWrpr {
      padding: 52px 34px 44px;
      line-height: 30px;

      p {
        color: #222f33;
      }
    }

    .detsWrpr {
      background-color: #2e3a60;
      padding: 40px 36px 58px;

      .sec {
        background-color: white;
        width: 100%;
        padding: 24px 12px;
        margin-bottom: 30px;
        border-radius: 2px;

        .iconWrpr {
          height: 100%;
          width: 16px;
          height: 100%;

          svg {
            margin-bottom: auto;
          }
        }

        p {
          line-height: 22px;
          margin-left: 24px;

          &::first-line {
            line-height: initial;
          }

          .label {
          }
        }
      }
    }

    .ftrSec {
      padding: 48px 34px;

      button,
      a {
        text-decoration: none;
        // display: flex;
        width: 100%;
        height: 30px;
        border: none;
        background-color: #297ba9;
        color: white;
        border-radius: 2px;
        cursor: pointer;

        &:nth-child(2) {
          margin-top: 14px;
        }
      }
    }
  }
}

// ************************************

@media screen and(min-width: 769px) and (max-width: 1040px) {
  .detailsPage {
    flex-direction: column;
    padding: 0px 12px;
    margin-top: 46px;

    .leftSec {
      width: 100%;

      // h2 {
      // 	font-size: 16px;
      // }

      // .addressP {
      // 	font-size: 16px;
      // }

      .imgsWrpr {
        width: 100%;
        .tag {
          display: none;
        }
        .mainImg {
          width: 100%;
        }

        .thumbsSec {
          display: grid;
          grid-template-columns: 64px 64px 64px 64px 64px 64px 64px 64px;
          column-gap: auto;
          row-gap: 14px;
          justify-content: space-around;
        }

        .thumb {
          height: 64px;
          width: 64px;
        }
      }
    }

    .rightSec {
      margin-left: 0px;
      width: 100%;
      margin-top: 40px;
    }
  }
}

// ************************************

@media screen and(max-width: 768px) {
  .detailsPage {
    flex-direction: column;
    padding: 0px 12px;
    margin-top: 20px;

    .leftSec {
      width: 100%;

      h2 {
        font-size: 16px;
      }

      .addressP {
        font-size: 16px;
      }

      .imgsWrpr {
        width: 100%;
        .tag {
          display: none;
        }
        .mainImg {
          width: 100%;
        }

        .thumbsSec {
          display: grid;
          grid-template-columns: 64px 64px 64px 64px;
          column-gap: auto;
          row-gap: 14px;
          justify-content: space-around;
        }

        .thumb {
          height: 64px;
          width: 64px;
        }
      }
    }

    .rightSec {
      margin-left: 0px;
      width: 100%;
      margin-top: 40px;
    }
  }
}
