@import "./colors.scss";

body.modal-open {
  overflow: hidden !important;
}

* {
  position: relative;
  box-sizing: border-box;
  outline: none;
}
.ReactCrop {
  height: 100%;
  width: 100%;
  overflow: auto !important;

  // 	> div {
  // 		max-height: 100%;
  // 		max-width: 100%;
  // 		img {
  // 			max-height: 100%;
  // 			max-width: 100%;
  // 		}
  // 	}
}

.ReactCrop__drag-elements {
  position: initial;
}

.cntnt-wrpr {
  width: 100%;
  max-width: 1144px;
  margin: 0px auto;
  height: 100%;
  padding: 0px 16px;
}

.df {
  display: flex;

  &.ffc {
    flex-flow: column;
  }

  &.acc {
    justify-content: center;
    align-items: center;
  }

  &.acsa {
    align-items: center;
  }

  &.acse {
    align-items: flex-end;
  }

  &.acma {
    justify-content: center;
  }

  .mla {
    margin-left: auto;
  }

  .mra {
    margin-right: auto;
  }
}

// Font colors

.c-white {
  color: white;
}

// Font families

@font-face {
  font-family: "slate reg";
  src: url("./assets/fonts/slate/SlateRg.TTF");
  font-display: swap;
}

.ffsr {
  font-family: "slate reg";
}

@font-face {
  font-family: "slate med";
  src: url("./assets/fonts/slate/SlateMedium.TTF");
  font-display: swap;
}

.ffsm {
  font-family: "slate med";
}

@font-face {
  font-family: "slate book";
  src: url("./assets/fonts/slate/SlateBook.TTF");
  font-display: swap;
}

.ffsbook {
  font-family: "slate book";
}

@font-face {
  font-family: "slate bold";
  src: url("./assets/fonts/slate/SlateBold.TTF");
  font-display: swap;
}

.ffsb {
  font-family: "slate bold";
}

@font-face {
  font-family: "lora reg";
  src: url("./assets/fonts/lora/Lora-Regular.ttf");
  font-display: swap;
}

.fflr {
  font-family: "lora reg";
}

@font-face {
  font-family: "lora med";
  src: url("./assets/fonts/lora/Lora-Medium.ttf");
  font-display: swap;
}

.fflm {
  font-family: "lora med";
}

// Font sizes

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs23 {
  font-size: 23px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs58 {
  font-size: 58px;
}

.fs68 {
  font-size: 68px;
}

.tac {
  text-align: center;
}

.__btn {
  height: 66px;
  padding: 0px 38px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.1s;
  display: inline-block;
  text-decoration: none;
  line-height: 66px;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    // filter: opacity(50%) blur(0.3px) grayscale(80%);
    cursor: not-allowed;
    // pointer-events: none;

    &:hover {
      transform: scale(1);
    }
  }

  &.light-blue {
    color: white;
    background-color: $lightBlue;
    border: 1px solid $lightBlue;

    &:hover {
      background-color: $buttonColor;
      border-color: $buttonColor;
    }
  }

  &.bordered {
    background: none;
    border: 1px solid $buttonColor;
    color: $buttonColor;
    &:hover {
      background-color: $lightBlue;
      border-color: $lightBlue;
      color: white;
    }
  }
}

@media screen and (min-width: 651px) and (max-width: 1040px) {
  .cntnt-wrpr {
    padding: 0px 30px;
  }
}

[class^="mob"] {
  display: none;
}

@media screen and (max-width: 650px) {
  [class^="mob"] {
    display: initial;
  }

  .cntnt-wrpr {
    padding: 0px 24px;
  }

  .desktop-only {
    display: none;
  }
}

.page-devider-sec {
  margin: 70px 0px;

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 66px;
    flex-shrink: 0;
    margin: 0px 8px;
    background-color: $lightBlue;
    border: none;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      background-color: $buttonColor;
      border-color: $buttonColor;
      transform: scale(1.03);
    }
  }

  .centerpiece {
    width: 232px;
    height: 66px;
    flex-shrink: 0;
    margin: 0px 8px;
  }

  .line-marker {
    background-color: rgba(151, 151, 151, 0.5);
    // border-top: 1px solid rgba(151, 151, 151, 0.5);
    width: 100%;
    display: block;
    height: 1px;

    &:nth-child(1) {
      margin-left: 12vw;
    }

    &:nth-last-child(1) {
      margin-right: 12vw;
    }
  }
}
